<template>
	<div>
		<div class="col-md-12" style="margin-top:20px">
			<div class="col-md-6">
				<h3 class="page-header">
					<i class="fa fa fa-users animated bounceInDown show-info"></i>
					Add Sub Category
				</h3>
			</div>
		</div>
		<form @submit.prevent="onSubmit" @keydown="form.errors.clear($event.target.name)" class="p-8 lg:w-1/2 md:mx-auto">
			<div class="col-md-12 ">
				<div class="panel panel-cascade">
					<div class="panel-body text-center">
						<div class="col-md-12 mt-20">

							<div class="form-group row">
								<div class="col-md-4">
									<label class=" control-label">Select Category<span
											style="color: rgba(255, 0, 0, 0.67)">*</span>
									</label>
								</div>
								<div class="col-md-4">
									<select name="Parent Id" class="form-control form-cascade-control input-small"
										v-model="form.parent_id">
										<option selected>Select</option>
										<option v-for="item in parentcategories" v-bind:value="item.id" :key="item.id">
											{{ item.name }}</option>
									</select>
									<span class="invalid-feedback" role="alert">
										<strong></strong>
									</span>
								</div>
							</div>
							<div class="form-group row">
								<div class="col-md-4">
									<label class=" control-label">Add Product<span
											style="color: rgba(255, 0, 0, 0.67)">*</span>
									</label>
								</div>
								<div class=" col-md-4">
									<input type="text" class="form-control form-cascade-control input-small"
										v-model="form.name" required>
									<span class="invalid-feedback" role="alert">
										<strong></strong>
									</span>
								</div>
							</div>
							<div class="form-group ">
								
									<input type="button"
										style="background-color:#495b79!important;margin-top:23px;margin-left:35px;"
										value="Submit" @click="onSubmit()"
										class="btn bg-primary text-white btn-md chkcondition">
									<input type="button"
										style="background-color:#495b79!important;margin-top:23px;margin-left:35px;"
										value="Cancel" @click="cancel()"
										class="btn bg-primary text-white btn-md chkcondition">
							</div>
						</div>
					</div>
				</div>

			</div>
			<div class="cardbox1">
				<div class="col-md-12">
					<table class="table table-font">
						<thead>
							<tr>
								<th>S.No</th>
								<th>Category</th>
								<th>Product</th>
							</tr>
						</thead>
						<tr v-for="(item, index) in filters" v-bind:key="item.id" :item="item" :index="index">
							<td>{{ index + 1 }}</td>
							<td>
								<div>{{ getcategory(item.parent_id) }}</div>
							</td>
							<td>{{ item.name }}</td>
							<td><button type="button" class="badge btn-success btn-xs" @click="edit(item)"><i
										class="fa fa-edit"></i></button></td>
						</tr>
					</table>
				</div>
			</div>

		</form>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Constants from '../../components/utilities/Constants.vue'
export default {
	mixins: [Constants],
	data() {
		return {
			form: new window.Form({
				id: 0,
				name: '',
				parent_id: 0,
				active: 1
			})
		}
	},
	mounted() {
		this.refresh();
		if (this.edititem != null) {
			this.form = new window.Form(this.edititem);
		}
	},
	watch: {
		edititem: function () {
			if (this.edititem != null) {
				this.form = new window.Form(this.edititem);
			}
		}
	},
	computed: {
		...mapGetters([
			'edititem', 'categories'
		]),
		parentcategories() {
			return this.categories.filter(block => block.parent_id == 0)
		},
		filters() {
			return this.categories.filter(item => item.parent_id > 0)
		},
	},
	methods: {
		onSubmit: function () {
			this.$notify({
				text: 'Submitted',
				type: 'warning',
				duration: '80000',
				speed: '800',
				closeOnClick: false
			})
			this.$store.commit('assignloadingstatus', 1)
			this.form.submit(this, 'post', 'api/category/createupdate')
				.then(response => this.onSuccess(response))
				.catch(error => console.log(error));
		},
		onSuccess: function (data) {
			if (data.success == true) {
				this.$store.commit('updateappendcategories', data.data)
				this.$store.commit('assignviewno', 1)
				//location.reload()
				this.$notify({
					text: 'Registered Successfully',
					type: 'success',
					duration: '-1',
					speed: '100',
					closeOnClick: false
				})
				this.refresh();
				this.$store.commit('assignloadingstatus', 0)
			} else {
				this.$notify({
					text: 'Already Exists',
					type: 'error',
					duration: '80000',
					speed: '800',
					closeOnClick: false
				})
				this.$store.commit('assignloadingstatus', 0)
			}
		},
		cancel() {
			this.$store.commit('assignviewno', 1)
			this.$store.commit('assignloadingstatus', 0)
		},
		refresh() {
			this.$store.dispatch("fetchcategories");
		},
		edit(item) {
			this.$notify({
				text: 'Edit Product',
				type: 'success',
				duration: '80000',
				speed: '800',
				closeOnClick: false
			})
			this.$store.commit("assignedititem", item)
		},

	}
}

</script>
